import * as i0 from '@angular/core';
import { ElementRef, Directive, Inject, Output, NgModule } from '@angular/core';
import { tuiDragAndDropFrom, TuiDragStage } from '@taiga-ui/cdk/observables';
import { filter, map } from 'rxjs/operators';

/**
 * @deprecated not used anywhere
 */
class TuiDragDirective {
  constructor(el) {
    this.el = el;
    this.dragAndDropFrom$ = tuiDragAndDropFrom(this.el.nativeElement);
    this.start = this.dragAndDropFrom$.pipe(filter(({
      stage
    }) => stage === TuiDragStage.Start), map(({
      event
    }) => event));
    this.continues = this.dragAndDropFrom$.pipe(filter(({
      stage
    }) => stage === TuiDragStage.Continues), map(({
      event
    }) => event));
    this.end = this.dragAndDropFrom$.pipe(filter(({
      stage
    }) => stage === TuiDragStage.End), map(({
      event
    }) => event));
  }
}
TuiDragDirective.ɵfac = function TuiDragDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || TuiDragDirective)(i0.ɵɵdirectiveInject(ElementRef));
};
TuiDragDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiDragDirective,
  selectors: [["", "tuiDragStart", ""], ["", "tuiDragContinues", ""], ["", "tuiDragEnd", ""]],
  outputs: {
    start: "tuiDragStart",
    continues: "tuiDragContinues",
    end: "tuiDragEnd"
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiDragDirective, [{
    type: Directive,
    args: [{
      selector: '[tuiDragStart], [tuiDragContinues], [tuiDragEnd]'
    }]
  }], function () {
    return [{
      type: i0.ElementRef,
      decorators: [{
        type: Inject,
        args: [ElementRef]
      }]
    }];
  }, {
    start: [{
      type: Output,
      args: ['tuiDragStart']
    }],
    continues: [{
      type: Output,
      args: ['tuiDragContinues']
    }],
    end: [{
      type: Output,
      args: ['tuiDragEnd']
    }]
  });
})();

/**
 * @deprecated not used anywhere
 */
class TuiDragModule {}
TuiDragModule.ɵfac = function TuiDragModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || TuiDragModule)();
};
TuiDragModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiDragModule
});
TuiDragModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiDragModule, [{
    type: NgModule,
    args: [{
      declarations: [TuiDragDirective],
      exports: [TuiDragDirective]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiDragDirective, TuiDragModule };
